import React from "react";
import ToomarContainer from "../containers/ToomarContainer";
import Title from "../Utils/Title";
import "./ContactUs.scss";
import FollowUsInSocials from "../kit/FollowUsInSocials";
import Whatsapp from "../../assets/greenWhatsapp.svg";
import Telegram from "../../assets/blueTelegram.svg";
import Mobile from "../../assets/blueMobile.svg";
import GoogleMaps from "../../assets/googleMapsIcon.svg";
import Instagram from "../../assets/instagram.svg";

function ContactUs() {
  return (
    <ToomarContainer>
      <Title goldText={"تماس "} blackText={"با ما"} />
      <div className={"contactUsContainer"}>
        <p>
          طومار به عنوان بزرگترین فروشگاه اینترنتی کارت و اقلام عروسی/ترحیم در
          خدمت شما عزیزان میباشد.
        </p>
        <p>
          توجه توجه{" "}
          <span style={{ fontSize: "24px" }}>
            لطفا قبل از تماس گرفتن متن زیر و قسمت{" "}
            <a
              href="/repetitive-questions"
              target="_blank"
              style={{ color: "#C69C61", textDecoration: "underline" }}
            >
              سوالات متداول
            </a>{" "}
            را مطالعه کنید
          </span>
        </p>
        <p>
          مدت زمان آماده سازی و ارسال محصولات برای سفارشات داخل تهران 3 الی 5
          روز کاری از لحظه ثبت سفارش و برای خارج از تهران 4 الی 7 روز کاری
          میباشد.{" "}
        </p>
        <p>
          در سفارشات دعوتنامه ها و محصولات چاپی و دیجیتال قبل از چاپ و نهایی
          کردن طرح ، عکس متن طراحی شده(در تلگرام، واتساپ یا سایر پیام رسان
          ها)برایتان ارسال میشود که تایید کنید.
        </p>
        <p style={{ color: "red", textAlign: "center", fontSize: "12px" }}>
          تمامی قیمت های ذکر شده ، قیمت نهایی محصولات (کارت های دعوت شامل هزینه
          چاپ و طراحی و... ) میباشد و قیمت ها بروز است.
        </p>
        <p>
          مشاوره و راهنمایی{" "}
          {/* <span style={{ fontSize: "20px" }}>
            زمان پاسخگویی تلفنی شنبه تا چهارشنبه از ۱۰ صبح تا ۷ بعد از ظهر
            (پنجشنبه ها از ۱۰ صبح تا ۵ بعد از ظهر)
          </span> */}
        </p>
        <div>
          <p>
            برای مشاوره و راهنمایی در مورد ثبت سفارش با شماره های زیر تماس
            بگیرید:
            <span style={{ color: "red", fontSize: "12px" }}>
              (پیشنهاد میشود قبل از تماس به قسمت{" "}
              <a
                href="/repetitive-questions"
                target="_blank"
                style={{ color: "black", textDecoration: "underline" }}
              >
                سوالات متداول
              </a>{" "}
              مراجعه نمایید)
            </span>
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <div>
              <img src={require("../../assets/telephone.png")} alt={"mobile"} />
              <p>تماس تلفنی</p>
              <p>۷۷۶۳۶۶۲۳ - ۰۲۱</p>
            </div>
            <span
              style={{
                fontSize: "24px",
                color: "#C69C61",
                fontFamily: "Aviny",
              }}
            >
              زمان پاسخگویی تلفنی شنبه تا چهارشنبه از ۱۰ صبح تا ۷ بعد از ظهر
              (پنجشنبه ها از ۱۰ صبح تا ۵ بعد از ظهر)
            </span>
          </div>
          <div>
            <a
              href={"http://wa.me/989054816441"}
              target={"_blank"}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img src={Whatsapp} alt={"whatsapp"} />
              <p
                style={{
                  color: "black",
                  fontSize: "12px",
                  margin: "4px 0px 0px",
                }}
              >
                واتساپ راهنمایی سفارشات
              </p>
            </a>
            <a
              href={"http://t.me/toomar_aroosicards"}
              target={"_blank"}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img src={Telegram} alt={"telegram"} />
              <p
                style={{
                  color: "black",
                  fontSize: "12px",
                  margin: "4px 0px 0px",
                }}
              >
                تلگرام راهنمایی سفارشات
              </p>
            </a>
          </div>
        </div>
        <p>تماس برای پیگیری سفارشات </p>
        <p>
          پس از ثبت سفارش شما عزیزان؛ لینکی حاوی پیگیری سفارش برایتان پیامک
          میشود که میتوانید با کلیک بر روی آن لینک از وضعیت سفارش خود مطلع شوید.
        </p>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <img
                style={{ width: "24px", height: "24px" }}
                src={require("../../assets/telephone.png")}
                alt={"mobile"}
              />
              <p
                style={{
                  margin: "0px 20px",
                  fontSize: "18px",
                  fontFamily: "IRANYekan",
                  fontWeight: "bold",
                }}
              >
                شماره تماس
              </p>
              <p
                style={{
                  margin: "0px",
                  fontSize: "18px",
                  fontFamily: "IRANYekan",
                  fontWeight: "bold",
                }}
              >
                ۷۷۶۳۶۶۲۲ - ۰۲۱
              </p>
            </div>
            <span
              style={{
                fontSize: "24px",
                color: "#C69C61",
                fontFamily: "Aviny",
              }}
            >
              زمان پاسخگویی تلفنی شنبه تا چهارشنبه از ۱۰ صبح تا ۷ بعد از ظهر
              (پنجشنبه ها از ۱۰ صبح تا ۵ بعد از ظهر)
            </span>
          </div>
          <div>
            <a
              href={"http://wa.me/989057837887"}
              target={"_blank"}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img src={Whatsapp} alt={"whatsapp"} />
              <p
                style={{
                  color: "black",
                  fontSize: "12px",
                  margin: "4px 0px 0px",
                }}
              >
                واتساپ پیگیری سفارشات
              </p>
            </a>
            <a
              href={"http://t.me/+989057837887"}
              target={"_blank"}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img src={Telegram} alt={"telegram"} />
              <p
                style={{
                  color: "black",
                  fontSize: "12px",
                  margin: "4px 0px 0px",
                }}
              >
                تلگرام پیگیری سفارشات
              </p>
            </a>
          </div>
        </div>

        <p
          style={{
            fontFamily: "Aviny",
            fontSize: "24px",
            margin: "16px 0px 12px",
          }}
        >
          ادرس دفتر فروش برای مراجعه حضوری
        </p>
        <p>
          خیابان ابن سینا جنوب به سمت میدان بهارستان، بعد از بیمارستان طرفه ،
          کوچه میر افضلی پلاک یک طبقه اول (ساختمان اتو گالری کاشانی)
        </p>
        <p
          style={{
            fontFamily: "Aviny",
            fontSize: "24px",
            margin: "16px 0px 12px",
          }}
        >
          ساعات کاری مجموعه برای مراجعه حضوری :
        </p>
        <p>شنبه تا ۴ شنبه از ساعت ۱۰ صبح تا ۷ شب</p>
        <p>پنجشنبه ها ۱۰ صبح تا ۵ بعد از ظهر</p>
        <p>جمعه ها و تعطیلات با هماهنگی قبلی داخل استوری ها اعلام میشود</p>
        <a
          href={"https://www.instagram.com/toomar_aroosicard/"}
          target={"_blank"}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img
              style={{ width: "40px", height: "40px" }}
              src={Instagram}
              alt="instagram"
            />
            <p
              style={{
                margin: "12px 4px 12px 0px",
                fontFamily: "Aviny",
                fontSize: "32px",
                color: "rgb(198, 156, 97)",
              }}
            >
              TOOMAR_AROOSICARD
            </p>
          </div>
        </a>
        <p
          style={{
            width: "100%",
            textAlign: "center",
            fontFamily: "Aviny",
            fontSize: "24px",
            cursor: "pointer",
          }}
          onClick={() =>
            window.open("https://www.google.com/maps?q=35.694610,51.434272")
          }
        >
          <img
            src={GoogleMaps}
            style={{
              verticalAlign: "middle",
              width: "32px",
              marginLeft: "2px",
            }}
            alt="google-map"
          />
          برای مسیریابی کلیک کنید.
        </p>
        <div>
          <img
            style={{
              marginLeft: "10px",
              width: "calc(50% - 10px)",
            }}
            src={require("../../assets/locationImg3.jpg")}
            alt="toomar"
          />
          <img
            style={{
              marginRight: "10px",
              width: "calc(50% - 10px)",
            }}
            src={require("../../assets/locationImg2.jpg")}
            alt="toomar"
          />
          <img
            width={"100%"}
            src={require("../../assets/locationImg1.jpg")}
            alt="toomar"
          />
          <img
            width={"100%"}
            src={require("../../assets/locationImg4.jpg")}
            alt="toomar"
          />
          <img
            width={"100%"}
            src={require("../../assets/locationImg5.jpg")}
            alt="toomar"
          />
        </div>
        <p>پست الکترونیک</p>
        <p>info@toomar.co</p>
        <FollowUsInSocials />
      </div>
    </ToomarContainer>
  );
}

export default ContactUs;
