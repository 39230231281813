import axios from "axios";
import { BASE_URL } from "../../constants";
import {
  GET_BOUGHT_PRODUCTS,
  GET_BUYING_PRODUCTS,
  GET_BUY_PRODUCT_DETAILS,
  GET_FAVORITE_PRODUCT,
  SET_PRODUCT_OBJECTS,
} from "./types";
import queryString from "query-string";
// const BASE_URL = 'http://stage.toomar.co/api/'

export const getAllProducts =
  ({ ordering = "-score", category }, onSuccess) =>
  async (dispatch) => {
    const qs = queryString.stringify({ ordering, category });
    try {
      const response = await axios.get(`${BASE_URL}products?${qs}`);
      onSuccess && onSuccess(response);
    } catch (err) {
      console.log(err);
    }
  };

export const getAllProductsWithUrl = (url, onSuccess) => async (dispatch) => {
  try {
    const response = await axios.get(url);
    onSuccess && onSuccess(response);
  } catch (err) {
    console.log(err);
  }
};

export const getCategories = (filter, onSuccess) => async (dispatch) => {
  try {
    const response = await axios.get(
      filter !== undefined
        ? `${BASE_URL}categories?${filter}`
        : `${BASE_URL}categories`
    );
    onSuccess && onSuccess(response);
  } catch (err) {
    console.log(err);
  }
};

export const getBlogs = (filter, onSuccess) => async (dispatch) => {
  try {
    const response = await axios.get(
      filter !== undefined ? `${BASE_URL}blog?${filter}` : `${BASE_URL}blog`
    );
    onSuccess && onSuccess(response);
  } catch (err) {
    console.log(err);
  }
};

export const getOneBlog = (id, onSuccess) => async (dispatch) => {
  try {
    const response = await axios.get(`${BASE_URL}blog/${id}`);
    onSuccess && onSuccess(response);
  } catch (err) {
    console.log(err);
  }
};

export const getOneProduct = (id, onSuccess) => async (dispatch) => {
  try {
    const response = await axios.get(`${BASE_URL}products/${id}`);
    onSuccess && onSuccess(response);
  } catch (err) {
    console.log(err);
  }
};

export const getOneProductDigital = (id, onSuccess) => async (dispatch) => {
  try {
    const response = await axios.get(`${BASE_URL}digital/${id}`);
    onSuccess && onSuccess(response);
  } catch (err) {
    console.log(err);
  }
};

export const getOneProductCard = (id, onSuccess) => async (dispatch) => {
  try {
    const response = await axios.get(`${BASE_URL}cards/${id}`);
    onSuccess && onSuccess(response);
  } catch (err) {
    console.log(err);
  }
};

export const getSliders = (filter, onSuccess) => async (dispatch) => {
  try {
    const response = await axios.get(
      filter !== undefined ? `${BASE_URL}slider?${filter}` : `${BASE_URL}slider`
    );
    onSuccess && onSuccess(response);
  } catch (err) {
    console.log(err);
  }
};

export const getOneCard = (id, onSuccess) => async (dispatch) => {
  try {
    const response = await axios.get(`${BASE_URL}cards/${id}`);
    onSuccess && onSuccess(response);
  } catch (err) {
    console.log(err);
  }
};

export const getCardTexts = (filter, onSuccess) => async (dispatch) => {
  try {
    const response = await axios.get(
      filter != undefined
        ? `${BASE_URL}card_texts?${filter}`
        : `${BASE_URL}card_texts`
    );
    onSuccess && onSuccess(response);
  } catch (err) {
    console.log(err);
  }
};

export const getMoreCardTexts =
  (filter, url, onSuccess) => async (dispatch) => {
    try {
      const response = await axios.get(
        filter != undefined ? `${url}&${filter}` : `${url}`
      );
      onSuccess && onSuccess(response);
    } catch (err) {
      console.log(err);
    }
  };

export const getCardTitles = (filter, onSuccess) => async (dispatch) => {
  try {
    const response = await axios.get(
      filter != undefined
        ? `${BASE_URL}card_titles?${filter}`
        : `${BASE_URL}card_titles`
    );
    onSuccess && onSuccess(response);
  } catch (err) {
    console.log(err);
  }
};

export const getOneDigital = (id, onSuccess) => async (dispatch) => {
  try {
    const response = await axios.get(`${BASE_URL}digital/${id}`);
    onSuccess && onSuccess(response);
  } catch (err) {
    console.log(err);
  }
};

export const getOneGift = (id, onSuccess) => async (dispatch) => {
  try {
    const response = await axios.get(`${BASE_URL}gift/${id}`);
    onSuccess && onSuccess(response);
  } catch (err) {
    console.log(err);
  }
};

export const sentComment = (data, onSuccess, onFailed) => async (dispatch) => {
  try {
    const response = await axios.post(`${BASE_URL}product/feedback/`, data);
    onSuccess && onSuccess(response);
  } catch (err) {
    console.log(err);
    onFailed && onFailed();
  }
};

export const sentTicket = (data, onSuccess) => async (dispatch) => {
  try {
    const response = await axios.post(`${BASE_URL}ticket/`, data);
    onSuccess && onSuccess(response);
  } catch (err) {
    console.log(err);
  }
};

export const setBuyingProduct = (data) => async (dispatch) => {
  dispatch({
    type: GET_BUYING_PRODUCTS,
    payload: data,
  });
};

export const setProductObjects = (data) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCT_OBJECTS,
    payload: data,
  });
};

export const setBoughtProduct = (data) => async (dispatch) => {
  dispatch({
    type: GET_BOUGHT_PRODUCTS,
    payload: data,
  });
};

export const setFinishedProductDetails = (data) => async (dispatch) => {
  dispatch({
    type: GET_BUY_PRODUCT_DETAILS,
    payload: data,
  });
};

export const submitBuyProducts =
  (data, onSuccess, onFailed) => async (dispatch) => {
    try {
      const response = await axios.post(`${BASE_URL}submit_order/`, data);
      if (response.payment_link == -1) {
        onFailed && onFailed();
      } else {
        onSuccess && onSuccess(response);
      }
    } catch (err) {
      onFailed && onFailed();
    }
  };

export const getRecommendations = (id, onSuccess) => async (dispatch) => {
  try {
    const response = await axios.get(`${BASE_URL}recommendation/${id}`);
    onSuccess && onSuccess(response);
  } catch (err) {
    console.log(err);
  }
};

export const getQuestions = (onSuccess) => async (dispatch) => {
  try {
    const response = await axios.get(`${BASE_URL}qa`);
    onSuccess && onSuccess(response);
  } catch (err) {
    console.log(err);
  }
};

export const getCategoriesTree = (onSuccess) => async (dispatch) => {
  try {
    const response = await axios.get(`${BASE_URL}category/tree`);
    onSuccess && onSuccess(response);
  } catch (err) {
    console.log(err);
  }
};

export const getSelectedCategories = (onSuccess) => async (dispatch) => {
  try {
    const response = await axios.get(`${BASE_URL}categories/?selected=true`);
    onSuccess && onSuccess(response);
  } catch (err) {
    console.log(err);
  }
};

export const getSearchResults = (name, onSuccess) => async (dispatch) => {
  try {
    const response = await axios.get(`${BASE_URL}search/${name}`);
    onSuccess && onSuccess(response);
  } catch (err) {
    console.log(err);
  }
};

export const verifyBuyResult = (orderId, onSuccess) => async (dispatch) => {
  try {
    let data = {
      order_id: orderId,
    };
    const response = await axios.post(`${BASE_URL}verify/`, data);
    onSuccess && onSuccess(response);
  } catch (err) {
    console.log(err);
  }
};

export const followupOrderStatus =
  (orderCode, onSuccess, onFailed) => async (dispatch) => {
    try {
      const response = await axios.post(`${BASE_URL}order_status/${orderCode}`);
      onSuccess && onSuccess(response);
    } catch (err) {
      onFailed && onFailed();
    }
  };

export const getBanner = (id, onSuccess) => async (dispatch) => {
  try {
    const response = await axios.get(`${BASE_URL}banners/${id}`);
    onSuccess && onSuccess(response);
  } catch (err) {
    console.log(err);
  }
};

export const setFavoriteProducts = (data) => async (dispatch) => {
  dispatch({
    type: GET_FAVORITE_PRODUCT,
    payload: data,
  });
};

export const handleCheckDiscountCode =
  (code, onSuccess, onFailed) => async (dispatch) => {
    try {
      const response = await axios.post(`${BASE_URL}check_discount/${code}`);
      onSuccess && onSuccess(response);
    } catch (err) {
      onFailed && onFailed();
      console.log(err);
    }
  };
